import React from 'react'

type DataProps = {
  title: string
}

const EntryTitle: React.FC<DataProps> = ({ title }) => (
  <div className="entry-title">
    <h2>{title}</h2>
  </div>
)

export default EntryTitle
