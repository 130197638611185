import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from './layout'
import { MdxArticleFields } from '../models/mdx'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import EntryTitle from '../components/entryTitle'
import EntryMeta from '../components/entryMeta'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import Section from '../components/section'
import TableOfContents from '../components/tableOfContents'
import Weixin from '../components/support/weixin'
import Alipay from '../components/support/alipay'
import Gongzhonghao from '../components/support/gongzhonghao'
import GoogleAd from '../components/googleAd'
import BilibiliVideo from '../components/bilibiliVideo'

type DataProps = {
  mdx: MdxArticleFields
}

const ArticleLayout: React.FC<PageProps<DataProps>> = ({ data: { mdx } }) => (
  <>
    <SEO
      title={mdx.frontmatter.title}
      description={mdx.frontmatter.description}
    />
    <Layout>
      <Section transparent={true}>
        <div className="row gutter-40 col-mb-80">
          <div className="postcontent col-lg-9">
            <div className="single-post mb-0">
              <div className="entry clearfix">
                <EntryTitle title={mdx.frontmatter.title} />
                <EntryMeta article={mdx} />
                {mdx.frontmatter.bilibiliVideoId ? (
                  <BilibiliVideo videoId={mdx.frontmatter.bilibiliVideoId} />
                ) : (
                  <Img
                    fluid={mdx.frontmatter.feature_image.childImageSharp.fluid}
                    className="mb-2"
                  />
                )}
                <div className="entry-content mt-0">
                  <MDXRenderer>{mdx.body}</MDXRenderer>
                </div>
              </div>
            </div>
            <div>
              <GoogleAd
                client="ca-pub-5852717197941523"
                slot="5612722128"
                style={{ display: 'block' }}
                format="auto"
                responsive="true"
              />
            </div>
          </div>
          <div className="sidebar col-lg-3">
            <div className="sidebar-widgets-wrap">
              <TableOfContents headings={mdx.headings} />
              <div className="widget clearfix mt-3 text-center">
                <h4>广告</h4>
                <GoogleAd
                  client="ca-pub-5852717197941523"
                  slot="2903832143"
                  style={{ display: 'block' }}
                  format="auto"
                  responsive="true"
                />
              </div>
              <div className="widget clearfix mt-2 text-center">
                <h4>微信公众号</h4>
                <Gongzhonghao />
              </div>
              <div className="widget clearfix mt-2 text-center">
                <h4>赞赏</h4>
                <Weixin />
                <Alipay />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  </>
)

export default ArticleLayout

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      ...MdxArticleFields
    }
  }
`
