import React from 'react'
import { ExternalLink } from 'react-external-link'

type DataProps = {
  videoId: string;
}

const BilibiliVideo: React.FC<DataProps> = ({ videoId }) => (
  <div>
    <iframe src={`//player.bilibili.com/player.html?bvid=${videoId}`} scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen={true} height="600"/>
    <ExternalLink href={`https://www.bilibili.com/video/${videoId}/`}><p>防白板链接</p></ExternalLink>
  </div>
)

export default BilibiliVideo
