import React from 'react'
import Img from 'gatsby-image'
import { FixedImage, FluidImage } from '../models/image'

export const FluidImageComponent: React.FC<{ img: FluidImage }> = ({ img }) => (
  <Img fluid={img.childImageSharp.fluid} />
)

export const FixedImageComponent: React.FC<{ img: FixedImage }> = ({ img }) => (
  <Img fixed={img.childImageSharp.fixed} />
)
