import React from 'react'
import Slugger from 'github-slugger'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { MdxHeading } from '../models/mdx'

const TableOfContents: React.FC<{ headings: MdxHeading[] }> = ({
  headings,
}) => {
  const slugger = new Slugger()
  const displayHeadings = headings.filter(heading => heading.depth !== 1)
  const headingMap = {
    1: 0,
    2: 1,
    3: 4,
    4: 6,
    5: 6,
    6: 6,
  }
  return displayHeadings.length > 0 ? (
    <div className="widget widget_links widget-li-noicon clearfix">
      <h4 className="text-center">内容大纲</h4>
      <ul>
        {headings
          .filter(heading => heading.depth !== 1)
          .map((heading, index) => (
            <li key={index} className={'ml-' + headingMap[heading.depth]}>
              <Link to={'#' + slugger.slug(heading.value)}>
                <FontAwesomeIcon icon={faAngleRight} className="mr-1" />
                {heading.value}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  ) : null
}

export default TableOfContents
