import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { FixedImageComponent } from '../basicImage'

const Gongzhonghao = () => (
  <StaticQuery
    query={graphql`
      {
        file(relativePath: { glob: "support/gongzhonghao.jpg" }) {
          childImageSharp {
            fixed(height: 200, quality: 100) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={data => <FixedImageComponent img={data.file} />}
  />
)

export default Gongzhonghao
